.work-container {
  background-color: #1e1e1e;
  height: 100%;
  width: 90%;
  margin: 90px auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
}
.work-page-title {
  font-size: 35px;
  text-align: center;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}

.projects {
  display: grid;
  grid-template-rows: repeat(auto, 1fr);
  grid-gap: 50px;
  margin: 50px auto;
  color: #fff;
  animation: slide-down 600ms ease-out forwards;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.project {
  background-color: #fff;
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.odd {
  background: linear-gradient(to right, #12100e, #2b4162);
}

.even {
  background: linear-gradient(to right, #12100e, #2b4162);
}

.project-name {
  font-size: 25px;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}

.project-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.project-image {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.project-image img {
  width: 300px;
  height: 100%;
}

.project-desc-details {
  flex: 1;
}

.project-links {
  display: flex;
  align-items: center;
  margin: 20px auto;
  column-gap: 10px;
}

.project-link {
  cursor: pointer;
  margin-right: 10px;
  font-size: 15px;
  background-color: #617aa0;
  border: none;
  border-radius: 10px;
  padding: 10px;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  font-family: 'Montserrat', sans-serif;
}

.project-link:hover {
  background-color: #1a3d76;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.project-description {
  width: 100%;
  /* text-align:inherit; */
  text-align: left;
  margin: 10px 0px;
  font-family: 'Montserrat', sans-serif;
  text-align: justify;
}

@media (max-width: 992px) {
  .work-container {
    width: 100vw;
  }
  .project {
    display: grid;
    width: 80%;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
  }
  .projects {
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .project-details {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
  }
  .project-image,
  .project-description,
  .project-links {
    justify-content: center;
    align-items: center;
  }
  .project-image {
    margin: 0px auto;
    max-width: 100%;
  }
  .skills-icons-container {
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    width: fit-content;
  }
  .project-description {
    width: 100%;
    /* padding: 0px 10%; */
    text-align: justify;
  }
}
