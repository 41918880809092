.contact-page-container {
  padding: 50px;
  text-align: center;
  color: #fff;
  display: grid;
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
  margin-top: 30px;
  animation: slide-down 600ms ease-out forwards;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.contact-email {
  text-decoration: none;
  color: #8b949e;
}

.page-title {
  font-size: 40px;
  color: white;
  font-weight: bold;
  margin-bottom: 25px;
  font-family: 'Poppins', sans-serif;
}

.page-sub-title {
  font-size: 20px;
  margin-bottom: 50px;
  margin-top: 20px;
  font-family: 'Montserrat', sans-serif;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 0px auto;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.contact-form textarea {
  height: 150px;
}

.contact-form button {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.contact-form button:hover {
  background-color: #333;
}

.contact-linkedIn {
  width: 30px;
  margin-left: 20px;
}
.contact-github {
  width: 30px;
  margin-left: 20px;
  object-fit: contain;
  background-color: white;
  border-radius: 50px;
  border: 1px solid #000;
}

@media (max-width: 992px) {
  .contact-page-container {
    justify-content: center;
    align-items: center;
  }
  .contact-page-form {
    justify-content: center;
    align-items: center;
  }
  .contact-form {
    width: 100%;
  }
}
