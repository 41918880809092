.skills-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  background-color: #1e1e1e;
  color: white;
  font-weight: bold;
  margin: 65px auto;
  animation: slide-down 600ms ease-out forwards;
}
@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1f1a1a;
  margin: 20px;
  width: 300px;
  height: 300px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #2b4162;
  border-radius: 30%;
  box-shadow: 0px 0px 20px 0px #2b4162;
}

.skill:hover {
  transform: translateY(-10px);
  box-shadow: 0px 0px 10px 0px white;
}

.skill img {
  width: 150px;
  height: 150px;
  border-radius: 0%;
  transition: all 0.3s ease-in-out;
  margin: auto 0px;
}

.skill img:hover {
  transform: scale(1.1);
}

.skill h3 {
  margin: auto 0px;
  font-size: 20px;
  font-weight: bold;
}

@media (max-width: 992px) {
  .skills-container {
    margin: 65px auto;
    height: 100%;
  }
  .skill img {
    width: 100px;
    height: 100px;
  }
}
